import client from '@/supabase'
import store from '@/store'

export default (): void => {
  const channel = client.channel('typing')

  channel
    .on('presence', { event: 'sync' }, () => {
      const newState = channel.presenceState()
      store.state.online = Object.values(newState).length
    })
    .on('broadcast', { event: 'sync' }, event => {
      store.state.typing =
        event.payload.isTyping === true
          ? store.state.typing + 1
          : store.state.typing - 1
    })
    .subscribe(async status => {
      if (status !== 'SUBSCRIBED') {
        return
      }

      channel.track({ status })
    })
}
