import { StoreModel } from '@/models/store.model'
import { createStore } from 'vuex'

export default createStore<StoreModel>({
  state: {
    name: localStorage.getItem('username') ?? '',
    isAnonymous: false,
    typing: 0,
    messageCount: 0,
    online: 1
  },
  mutations: {
    setName(state: StoreModel, newName: string) {
      state.name = newName
    },

    setIsAnonymous(state: StoreModel, isAnonymous: boolean) {
      state.isAnonymous = isAnonymous
    },

    setMessageCount(state: StoreModel, num: number) {
      state.messageCount = num
    },

    incrementMessageCount(state: StoreModel) {
      if (state.messageCount) {
        state.messageCount++
      } else {
        state.messageCount = 1
      }
    },

    decrementMessageCount(state: StoreModel) {
      if (state.messageCount >= 1) {
        state.messageCount--
      } else {
        state.messageCount = 0
      }
    },

    setOnline(state: StoreModel, num: number) {
      state.online = num
    },

    setTyping(state: StoreModel, num: number) {
      state.typing = num
    }
  },
  actions: {
    setName({ commit }, newName: string) {
      commit('setName', newName)
    },

    setIsAnonymous({ commit }, isAnonymous: boolean) {
      commit('setIsAnonymous', isAnonymous)
    },

    setMessageCount({ commit }, num: number) {
      commit('setMessageCount', num)
    },

    incrementMessageCount({ commit }) {
      commit('incrementMessageCount')
    },

    decrementMessageCount({ commit }) {
      commit('decrementMessageCount')
    },

    setOnline({ commit }, num: number) {
      commit('setOnline', num)
    },

    setTyping({ commit }, num: number) {
      commit('setTyping', num)
    }
  },
  getters: {
    getName(state: StoreModel) {
      return state.name
    },

    getIsAnonymous(state: StoreModel) {
      return state.isAnonymous
    },

    getTyping(state: StoreModel) {
      return state.typing
    },

    getOnlines(state: StoreModel) {
      return state.online
    },

    getMessageCount(state: StoreModel) {
      return state.messageCount
    }
  }
})
